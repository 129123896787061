import { CommonModule } from '@angular/common';
import { Component, EnvironmentInjector, OnInit } from '@angular/core';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { NavigationEnd, Router } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import * as moment from 'moment';
import { filter } from 'rxjs';

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    imports: [IonicModule, CommonModule],
})
export class AppComponent implements OnInit {
    constructor(public environmentInjector: EnvironmentInjector, private router: Router) {}

    ngOnInit() {
        moment.locale('sv');

        this.initializeAnalytics();
    }

    private initializeAnalytics() {
        const firebaseApp = getAnalytics();

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            logEvent(firebaseApp, 'page_view', {
                page_title: event.url,
                page_location: event.url,
                page_path: event.url,
            });
        });
    }
}
