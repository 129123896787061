import { enableProdMode, APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideHttpClient } from '@angular/common/http';
import { RouteReuseStrategy, provideRouter } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavController } from '@ionic/angular';

import { Actions, NgxsModule, Store } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import { DateFnsModule, DateFnsConfigurationService } from 'ngx-date-fns';
import { sv } from 'date-fns/locale';

import { AppComponent } from '@app/app.component';
import { appInit } from '@shared/helpers';
import { AppState, AuthState } from '@shared/state';
import { environment } from '@env';
import { TSuiteHttpModule } from '@shared/modules/http';
import { provideAnimations } from '@angular/platform-browser/animations';

import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { User } from '@shared/models';
import { routes } from '@app/routes';

Bugsnag.start({
    apiKey: environment.bugsnag.apiKey,
    releaseStage: environment.production ? 'production' : 'development',
    enabledReleaseStages: ['production'],
});

const swedishConfig = new DateFnsConfigurationService();
swedishConfig.setLocale(sv);

export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom([
            IonicModule.forRoot({
                mode: 'ios',
                backButtonText: 'Tillbaka',
            }),
            DateFnsModule.forRoot(),
            TSuiteHttpModule.forRoot({
                baseUrl: environment.api.url,
                defaultHeaders: {
                    'content-type': 'application/json',
                    source: 'aktivitet.fagelguidning.se',
                },
            }),
            NgxsModule.forRoot([AppState, AuthState], {
                developmentMode: !environment.production,
            }),
            NgxsStoragePluginModule.forRoot({
                key: ['auth'],
                afterDeserialize(obj, key) {
                    if (key === 'auth') {
                        if (obj.user) {
                            obj.user === new User(obj.user);
                        }
                    }

                    return obj;
                },
            }),
            NgxsLoggerPluginModule.forRoot({
                disabled: environment.production,
            }),
            provideFirebaseApp(() => initializeApp(environment.firebase)),
            provideAuth(() => getAuth()),
            provideAnalytics(() => getAnalytics()),
        ]),
        provideRouter(routes),
        provideHttpClient(),
        provideAnimations(),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: DateFnsConfigurationService, useValue: swedishConfig },
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            deps: [Store, Actions, NavController],
            multi: true,
        },
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
        ScreenTrackingService,
        UserTrackingService,
    ],
}).catch((err) => console.error(err));
