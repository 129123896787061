export const environment = {
    firebase: {
        projectId: 'fagelguidning',
        appId: '1:46656702462:web:536215d2b33b4fe8b322af',
        storageBucket: 'fagelguidning.appspot.com',
        apiKey: 'AIzaSyC5JqMc8laSrniYB-0lIOcOnqcw3kEZp7I',
        authDomain: 'fagelguidning.firebaseapp.com',
        messagingSenderId: '46656702462',
        measurementId: 'G-L59ZNZZYFK',
    },
    production: false,
    api: {
        url: 'https://api.fagelguidning.se',
        // url: 'http://localhost:3000',
    },
    bugsnag: {
        apiKey: '5dbcbd97924776577bfc9c64af60ae70',
    },
};
